var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('general-table',{ref:"countriesTable",attrs:{"api-url":_vm.apiUrl,"add-component-name":_vm.addComponentName,"edit-component":_vm.editComponent,"delete-content":false,"block-content":false,"edit-content":false,"view-content":true,"add-type":_vm.addType,"columns":_vm.filteredColumns,"type":_vm.type,"selectable":false},scopedSlots:_vm._u([{key:"cell(auto_proceed)",fn:function(data){return [_vm._t("show",function(){return [_c('div',{staticClass:"text-center"},[(data.item.auto_proceed === 1)?_c('span',{staticStyle:{"background-color":"#132a4d","border-radius":"10%","padding":"6px"}},[_c('feather-icon',{attrs:{"icon":"CheckIcon","size":"25","color":"white"}})],1):_c('span',{staticStyle:{"background-color":"#c9372c","border-radius":"10%","padding":"6px"}},[_c('feather-icon',{attrs:{"icon":"XIcon","size":"25","color":"white"}})],1)])]},null,data)]}},{key:"cell(offer)",fn:function(data){return [_vm._t("show",function(){return [_c('div',{staticClass:"text-center"},[(data.item.offer)?_c('span',[_vm._v(" "+_vm._s(data.item.offer.name)+" - "+_vm._s(data.item.offer.amount)+" % ")]):_vm._e()])]},null,data)]}},{key:"cell(view_details)",fn:function(data){return [_vm._t("show",function(){return [_c('div',{staticClass:"text-center"},[(_vm.viewContent)?_c('span',{attrs:{"title":"View"}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"EyeIcon"},on:{"click":function($event){return _vm.$router.push({
                  name: 'show-delegator',
                  params: { id: data.item.id }
                })}}})],1):_vm._e()])]},null,data)]}},{key:"cell(add_auto_proceed)",fn:function(data){return [_vm._t("show",function(){return [_c('div',{staticClass:"text-center"},[_c('b-btn',{staticStyle:{"font-size":"smaller","text-wrap":"nowrap","background-color":"#0564f2!important","border":"1px solid #0564f2!important"},on:{"click":function($event){return _vm.$router.push({
                name: 'add-auto-proceed',
                params: { id: data.item.id }
              })}}},[_vm._v(" Add Auto Proceed ")])],1)]},null,data)]}},{key:"cell(add_balance)",fn:function(data){return [_vm._t("show",function(){return [_c('div',{staticClass:"text-center"},[_c('b-btn',{staticStyle:{"font-size":"smaller","text-wrap":"nowrap","background-color":"#0564f2!important","border":"1px solid #0564f2!important"},on:{"click":function($event){return _vm.$router.push({
                name: 'add-balance',
                params: { id: data.item.id }
              })}}},[_vm._v(" Add Balance ")])],1)]},null,data)]}},{key:"cell(add_offer)",fn:function(data){return [_vm._t("show",function(){return [_c('div',{staticClass:"text-center"},[_c('b-btn',{staticStyle:{"font-size":"smaller","text-wrap":"nowrap","background-color":"#0564f2!important","border":"1px solid #0564f2!important"},on:{"click":function($event){return _vm.$router.push({
                name: 'add-offer-delegator',
                params: { id: data.item.id }
              })}}},[_vm._v(" Add Offer ")])],1)]},null,data)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <general-table
      ref="countriesTable"
      :api-url="apiUrl"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="false"
      :block-content="false"
      :edit-content="false"
      :view-content="true"
      :add-type="addType"
      :columns="filteredColumns"
      :type="type"
      :selectable="false"
    >
      <template #cell(auto_proceed)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="text-center">
            <span
              v-if="data.item.auto_proceed === 1"
              style="background-color: #132a4d; border-radius: 10%; padding: 6px;"
            >
              <feather-icon
                icon="CheckIcon"
                size="25"
                color="white"
              />
            </span>

            <span
              v-else
              style="background-color: #c9372c; border-radius: 10%; padding: 6px;"
            >
              <feather-icon
                icon="XIcon"
                size="25"
                color="white"
              />
            </span>
          </div>
        </slot>
      </template>
      <template #cell(offer)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="text-center">
            <span v-if="data.item.offer">
              {{ data.item.offer.name }} - {{ data.item.offer.amount }} %
            </span>
          </div>
        </slot>
      </template>

      <template #cell(view_details)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="text-center">
            <span
              v-if="viewContent"
              title="View"
            >
              <feather-icon
                icon="EyeIcon"
                class="cursor-pointer"
                @click="
                  $router.push({
                    name: 'show-delegator',
                    params: { id: data.item.id }
                  })
                "
              />
            </span>
          </div>
        </slot>
      </template>

      <template #cell(add_auto_proceed)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="text-center">
            <b-btn
              style="font-size: smaller; text-wrap: nowrap; background-color: #0564f2!important; border: 1px solid #0564f2!important;"
              @click="
                $router.push({
                  name: 'add-auto-proceed',
                  params: { id: data.item.id }
                })
              "
            >
              Add Auto Proceed
            </b-btn>
          </div>
        </slot>
      </template>
      <template #cell(add_balance)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="text-center">
            <b-btn
              style="font-size: smaller; text-wrap: nowrap; background-color: #0564f2!important; border: 1px solid #0564f2!important;"
              @click="
                $router.push({
                  name: 'add-balance',
                  params: { id: data.item.id }
                })
              "
            >
              Add Balance
            </b-btn>
          </div>
        </slot>
      </template>
      <template #cell(add_offer)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="text-center">
            <b-btn
              style="font-size: smaller; text-wrap: nowrap; background-color: #0564f2!important; border: 1px solid #0564f2!important;"
              @click="
                $router.push({
                  name: 'add-offer-delegator',
                  params: { id: data.item.id }
                })
              "
            >
              Add Offer
            </b-btn>
          </div>
        </slot>
      </template>
    </general-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      addComponentName: '',
      editComponent: '',
      addType: '',
      viewContent: true,
      type: 'page',
      filter: {},
      delegatorRole: '',
      columns: [
        { key: 'id', sortable: true },
        { key: 'name', label: 'Name' },
        { key: 'email', label: 'Email' },
        { key: 'phone', label: 'Phone' },
        { key: 'offer', label: 'Offer' },
        { key: 'wallet_balance', label: 'Wallet Balance' },
        { key: 'auto_proceed', label: 'Auto Proceed' },
        { key: 'view_details', label: 'View Details' },
        { key: 'add_auto_proceed', label: 'Add Auto Proceed' },
        { key: 'add_balance', label: 'Add Balance' },
        { key: 'add_offer', label: 'Add Offer' },
      ],
    }
  },
  computed: {
    ...mapState({
      role: state => state.login.role,
    }),
    filteredColumns() {
      //   "admin",
      // "manager",
      // "super-manager",
      // "hr",
      // "process-manager",
      // "accountant",
      // "super-accountant",
      // "markter"
      if (this.role === 'super-accountant' || this.role === 'accountant') {
        return this.columns.filter(column => [
          'name',
          'email',
          'phone',
          'offer',
          'wallet_balance',
          'auto_proceed',
          'view_details',
          'add_auto_proceed',
          'add_balance',
        ].includes(column.key))
      }
      if (this.role === 'markter') {
        return this.columns.filter(column => [
          'name',
          'email',
          'phone',
          'offer',
          'wallet_balance',
          'auto_proceed',
          'view_details',
          'add_offer',
        ].includes(column.key))
      }
      return this.columns
    },
    apiUrl() {
      const base = 'delegator/signed?'
      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      const queryParams = Object.entries(this.filter)
        .filter(([key, value]) => key && value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')

      return `${base}${queryParams}`
    },
  },
  mounted() {
    this.delegatorRole = this.role
  },
  methods: {
    filterTypes(type) {
      this.types = this.status.filter(item => item.text !== type)
    },
  },
}
</script>

<style></style>
